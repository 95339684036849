ul {
  list-style-type: none;
  display: inline-flex;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0em;
  margin-inline-end: 0em;
  padding-inline-start: 0;
}

li {
  margin-right: 0.3em;
}

li:not(:last-child)::after {
  content: ",";
}

.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--primary);
  min-height: 100vh;
  font-family: Dosis;
}

/* Header */
.projectHeader {
  display: flex;
  position: relative;
  width: 100vw;
  height: 12em;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: var(--secondary);
}

.projectBanner {
  width: 100%;
  height: 12em;
  object-fit: cover;
  opacity: 0.7;
  filter: grayscale(0.3);
}

.projectName {
  position: absolute;
  font-size: xxx-large;
  text-align: center;
  text-shadow: 0.06em 0.04em var(--accent-1);
}

.projectInfoWrapper {
  position: absolute;
  background-color: var(--primary);
  border-radius: 1em 0em 0em 0em;
  right: 0;
  bottom: 0;
  display: flex;
  padding-left: 0.6em;
  padding-right: 0.6em;
}

.projectInfo {
  padding: 0.8em;
  color: #000000c9;
}

/* Role and Tech Details */

.projectOverview {
  display: flex;
  flex-direction: column;
  font-size: large;
  color: white;
  width: 80%;
  align-items: center;
  margin-top: 0.8em;
}

.borderDecoration {
  position: absolute;
  width: 98%;
  height: 92%;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  border-image-source: url("./assets/corners.png");
  border-image-slice: 100 fill;
  border-image-width: 2em;
  border-image-repeat: stretch stretch;
}

.blockWrapper {
  display: flex;
  flex-direction: column;
  margin-right: 0.6em;
}

/* Role */
.roleDisplay {
  display: flex;
  height: 20em;
}

.roleTextWrapper {
  position: relative;
  width: 30em;
  background-color: var(--accent-2);
  margin-bottom: 0.4em;
}

.detailHeader {
  display: flex;
  font-size: x-large;
  justify-content: center;
  width: 100%;
  padding-top: 0.4em;
}

.smallHeader {
  display: flex;
  align-items: center;
  padding-left: 1em;
  padding-right: 0.4em;
}

.roleDescription {
  display: flex;
  padding: 1em 2em 1em 2em;
}

.roleImage {
  width: 30em;
  height: 20em;
  object-fit: cover;
}

/* Tech Details */
.projectDetailsWrapper {
  display: flex;
  background-color: var(--secondary);
  flex-direction: column;
  position: relative;
}

.techOverview {
  display: flex;
  height: 2em;
  margin-bottom: 0.4em;
  margin-top: 0.2em;
}

.listWrapper {
  display: flex;
  align-items: center;
}

/* Text and Image Display */

.projectDetails {
  display: flex;
  width: 80%;
  flex-direction: column;
}

.projectDetail {
  display: flex;
  margin-top: 1em;
  margin-bottom: 2em;
}

.projectDetail.right {
  flex-flow: row-reverse;
}

.projectDetailImage {
  width: 50%;
  height: 30em;
  object-fit: cover;
}

.projectDetailText {
  font-size: x-large;
  background-color: var(--accent-3);
  /* background: linear-gradient(to top left, var(--accent-2), var(--accent-3) 45%); */
  color: white;
  padding: 2em;
}

.linkButtonWrapper {
  display: flex;
  margin-top: 0.4em;
  justify-content: space-evenly;
}

.linkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.2em 0.6em 0.2em 0.6em;
  background-color: var(--accent-3);
  border: solid 0.2em var(--accent-2);
  border-radius: 0.4em;
  font-size: large;
  cursor: pointer;
  margin-right: 0.2em;
  margin-left: 0.2em;
}

.repoText {
  margin-left: 0.4em;
}

.repoImg {
  width: 2em;
  height: 2em;
}

@media only screen and (max-width: 768px) {
  .roleDisplay {
    flex-direction: column;
    height: 100%;
  }

  .blockWrapper {
    align-items: center;
    margin-right: 0;
  }

  .roleTextWrapper {
    width: 100%;
  }

  .roleImage {
    width: 17em;
    height: 10em;
    margin-top: 0.4em;
  }

  .borderDecoration {
    left: 0.2em;
  }

  .projectDetailsWrapper {
    width: 110%;
    padding: 0.2em;
    height: 8em;
  }

  ul {
    flex-wrap: wrap;
  }

  .techOverview {
    margin-bottom: 0.2em;
    margin-top: 0.2em;
    margin-right: 0.2em;
  }

  .roleDescription {
    padding: 0.6em 1em 0.6em 1.2em;
  }

  .projectDetail {
    flex-flow: column;
  }

  .projectDetail.right {
    flex-flow: column;
  }

  .projectDetailText {
    font-size: large;
  }

  .projectDetailImage {
    width: 100%;
    height: 20em;
  }

  .projectInfo {
    padding: 0.4em;
  }

  .projectName {
    font-size: xx-large;
  }
}
