.contactPage {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border: solid 0.1em var(--accent-2);
  border-radius: 2em;
  border-bottom-width: thick;
  padding: 1em 1em 1em 1em;
  position: fixed;
  top: 30%;
}

.contactText {
  font-size: xxx-large;
}

.contactDetailsWrapper {
  display: flex;
  width: 16em;
  justify-content: space-around;
}

.contactDetail {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 1em;
}

.contactIcon {
  width: 4em;
  height: 4em;
  cursor: pointer;
}

.contactIconName {
  font-size: large;
}

@media only screen and (max-width: 768px) {
  .contactText {
    font-size: xx-large;
  }
}
