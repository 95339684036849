.header {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
  height: 6%;
  background-color: #484745;
}

.header.showRoot {
  justify-content: space-between;
}

.menuWrapper {
  display: flex;
  margin-left: 2em;
  font-size: x-large;
  flex-direction: row;
  justify-content: space-around;
  color: var(--primary);
  height: 2.4em;
  align-items: center;
}

.rightMargin {
  margin-right: 1em;
}

.hamburgerMenu {
  flex-direction: column;
  width: 1.6em;
  height: 2em;
  margin-top: 1em;
  margin-right: 1em;
  cursor: pointer;
  display: none;
}

.hamburgerMenuBorder {
  border-top: solid 0.2em var(--primary);
  margin-bottom: 0.3em;
  width: 100%;
}

.hamburgerMenu:hover .hamburgerMenuBorder {
  border-color: var(--accent-2);
}

.sideMenu {
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  right: -6em;
  padding: 1em;
  font-size: xx-large;
  background-color: var(--accent-3);
  border: solid 0.08em var(--primary);
  color: var(--primary);
  transition: 0.3s;
  z-index: 15;
  display: none;
}

.sideMenu.open {
  right: 0;
  display: flex;
}

.sideMenuItem {
  margin-bottom: 1em;
  width: 100%;
  justify-content: center;
  display: flex;
}

.sideMenuCloseButton {
  height: 1em;
  width: 1em;
  position: relative;
  cursor: pointer;
}

.sideMenuCloseButton:after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0.4em;
  right: 0px;
  content: "\00d7";
  font-size: 2em;
  color: var(--primary);
  line-height: 0;
  text-align: center;
}

.fullTextMenu {
  display: flex;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .hamburgerMenu {
    display: flex;
  }

  .fullTextMenu {
    display: none;
  }

  .menuWrapper {
    margin-left: 1em;
  }
}
