.aboutPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: var(--primary);
  font-family: Dosis;
  margin-top: 2em;
  margin-bottom: 2em;
}

.aboutWrapper {
  display: flex;
  width: 50em;
  height: 32em;
  align-items: center;
}

.personalPhoto {
  width: 20em;
  height: 20em;
  border-radius: 2em;
}

.personalDetails {
  color: black;
  margin-left: 2em;
}

.personalDetailsTitle {
  text-shadow: 0.04em 0.04em var(--accent-1);
  font-size: xx-large;
  margin-bottom: 0.4em;
}

.personalDetailsBody {
  font-size: x-large;
}

@media only screen and (max-width: 768px) {
  .aboutWrapper {
    width: unset;
    height: unset;
    flex-direction: column;
  }

  .personalPhoto {
    width: 12em;
    height: 15em;
  }

  .personalDetails {
    margin: 2em;
  }
}
