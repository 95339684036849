@font-face {
  font-family: "Dosis";
  src: local("Dosis"), url("./fonts/Dosis/Dosis-Medium.ttf") format("truetype");
}

a {
  color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

a:hover {
  color: var(--accent-2);
}

/* Color Palette */
:root {
  /* Dark blue tech */
  /* --primary: #2c3531;
  --secondary: #116466;
  --accent-1: #ffcb9a;
  --accent-2: #d1e8e2; */
  /* Minimalist ** like this one */
  --primary: #eae7dc;
  --secondary: #8e8d8a;
  --accent-1: #e98074;
  --accent-2: #e85a4f;
  --accent-3: #484745;

  /* --primary: #25274d;
  --secondary: #464866;
  --accent-1: #2e9cca;
  --accent-2: #29648a; */

  --text-color: black;
  --copy-right: #484745;
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--primary);
  min-height: 100vh;
  font-family: Dosis;
  user-select: none;
  justify-content: space-between;
}

.title {
  display: flex;
  flex-direction: column;
  padding: 0.2em 0.4em 0.2em 0.4em;
  margin-top: 0.6em;
  font-size: xxx-large;
  text-align: center;
  align-items: center;
  border: solid 0.08em var(--accent-2);
  border-radius: 0.4em;
  border-bottom-width: 0.2em;
}

.name {
  text-shadow: 0.04em 0.04em var(--accent-1);
}

.jobTitle {
  font-size: xx-large;
}

.titleDivider {
  width: 4em;
  border: solid 0.01em var(--accent-1);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 4em 0em 4em 0em;
}

.copyRight {
  width: 100vw;
  height: 4em;
  background-color: var(--copy-right);
  z-index: 20;
  display: flex;
  align-items: center;
}

.copyRightNotice {
  color: var(--primary);
  font-size: large;
  width: 20em;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: xx-large;
  }

  .jobTitle {
    font-size: x-large;
  }
}
