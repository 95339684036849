.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.projectCard {
  display: flex;
  position: relative;
  width: 19.6em;
  height: 22em;
  margin-left: 1em;
  margin-bottom: 2em;
  margin-right: 1em;
  cursor: pointer;
}

.projectCard:hover .banner {
  background-color: transparent;
}

.projectCard.large {
  width: 80em;
}

.projectCardBorder {
  position: absolute;
  border-radius: 1.4em;
  width: 19em;
  height: 21.6em;
  border: solid 0.6em var(--accent-3);
  transition: 0.3s;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.projectCard:hover .projectCardBorder {
  width: 20em;
  height: 23em;
  border: solid 0.2em var(--accent-3);
}

.projectCardImage {
  border-radius: 1em;
  width: inherit;
  height: 16em;
  object-fit: cover;
  z-index: 10;
  transition: 0.3s;
}

.projectCard:hover .projectCardImage {
  transform: translateY(-2%);
}

.banner {
  position: absolute;
  display: flex;
  width: 100%;
  height: 9em;
  background-color: var(--accent-3);
  bottom: 0;
  border-radius: 0.4em;
  justify-content: center;
  align-items: flex-end;
  color: var(--primary);
  font-size: xx-large;
  transition: 0.3s;
  padding-bottom: 1em;
}

.projectCard:hover .banner {
  color: var(--accent-3);
}
